/* CK Editor */
.CKEditor .ck {
  white-space: pre-wrap;
  max-height: 150px;
}

.ck-powered-by-balloon {
  @apply !hidden;
}

.ck.ck-editor__editable_inline {
  @apply !p-0 !border-none;
  /*color: var(--text-default);*/
}

.ck.ck-editor__editable.ck-focused:not(.ck-editor__nested-editable) {
  @apply !shadow-none outline-none;
}

.ck-balloon-panel {
  @apply !rounded-md !border !border-grey-50 !shadow-lg;
}

.ck-balloon-panel_with-arrow::before,
.ck-balloon-panel_with-arrow::after {
  @apply hidden;
}

.ck.ck-button,
a.ck.ck-button {
  @apply !text-grey-300;
}

.ck.ck-toolbar__separator {
  @apply !bg-grey-50;
}

.ck.ck-link-actions .ck-button.ck-link-actions__preview .ck-button__label {
  @apply !text-primary-500;
}

.ck.ck-content,
.ck.ck-content > * {
  @apply prose prose-sm prose-p:m-0 prose-a:text-primary-500;
  font-size: var(--text-14);
  font-style: normal;
  font-weight: 400;
  line-height: 22.8px;
  letter-spacing: 0.1px;
  color: var(--text-default);
  max-width: 100vw !important;
  /*100ch*/
}

.ck.ck-inherit * {
  @apply prose prose-sm prose-p:m-0 prose-a:text-[inherit];
  font-size: inherit;
  font-style: normal;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  max-width: 100ch !important;
  /* 100ch */
}

.ck-placeholder {
  color: var(--text-disabled);

  /* Body Small/Regular */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  /* 150% */
  letter-spacing: 0.1px;
}

.ck.ck-reset_all,
.ck-reset_all *:not(.ck-reset_all-excluded *) {
  /* font-weight: inherit !important; */
  /* font-size: inherit !important; */
}

.ck-reset_all :not(.ck-reset_all-excluded *) {
  /* font-weight: inherit !important; */
  /* font-size: inherit !important; */
}

.ck.ck-reset_all,
.ck-reset_all *:not(.ck-reset_all-excluded *) {
  /* font-weight: inherit !important; */
  /* font-size: inherit !important; */
}

.ck-reset_all :not(.ck-reset_all-excluded *),
.ck.ck-reset_all {
  @apply !font-sans;
}

.ck-rounded-corners .ck.ck-list,
.ck.ck-list.ck-rounded-corners {
  @apply !rounded-lg;
}

.ck.ck-list__item > .ck-button {
  @apply !p-1 !px-3 flex;
}

.ck.ck-mentions {
  @apply !py-1 !w-[350px] !min-h-[150px] !max-h-[245px];
}

.ck-content .mention {
  color: var(--mentions-text) !important;
  background: unset !important;
}

.ck.ck-balloon-panel {
  overflow: hidden;
}

.ck.ck-list__item > .ck-button.ck-on {
  background: var(--ck-color-list-button-hover-background) !important;
}

.ck.ck-list__item > .ck-button.ck-on:hover:not(.ck-disabled) {
  @apply !bg-[var(--backgrounds-hover-clicked)] !text-[var(--color-grey-25)];
}

.ck.ck-list__item > .ck-button:hover:not(.ck-disabled) {
  @apply !bg-[var(--backgrounds-hover-clicked)] !text-[var(--color-grey-25)];
}

.ck.ck-balloon-panel {
  @apply !border-[var(--border-on-bg)] !bg-[var(--backgrounds-dropdowns)];
}

.ck.ck-list {
  @apply !bg-[var(--backgrounds-dropdowns)] !text-[var(--text-on-bg)];
}

.ck.ck-list__item {
  @apply !cursor-pointer;
}

.ck.ck-list__item > .ck-button.ck-on {
  @apply !bg-[var(--backgrounds-dropdowns)] !text-[var(--text-on-bg)];
}

.ck.ck-list__item > .ck-button.ck-on:hover:not(.ck-disabled) {
  @apply !bg-[var(--backgrounds-hover-clicked)] !text-[var(--color-grey-25)];
}

.ck.ck-content
  :where(strong):not(:where([class~='not-prose'], [class~='not-prose'] *)),
.ck.ck-content
  > *
  :where(strong):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  color: inherit !important;
  font-weight: bold !important;
}

/* scroll margin blurred */
.blurScroll_top {
  background: linear-gradient(0deg, rgba(18, 18, 18, 0) 0%, #121212 100%);
}

.scrollBg_bottom {
  background: var(--scroll-bg-blur);
}
