/* use 'Cmd + f or Ctrl + f' to search this file by their filename.tsx  */

/* HeaderTexts.tsx */

.header_text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
}

.header_text .title,
.header_text .subtitle {
  color: var(--text-default);
}

.header_text .snippet {
  color: var(--text-neutral);
}

.header_text .snippet span {
  color: var(--text-default);
  font-weight: 500;
}

/* HorizontalDivider.tsx */

.hr {
  width: 100%;
  background: var(--border-divider);
}

.hr.small {
  height: 1px;
}

.hr.large {
  height: 8px;
}

/* Button.tsx */

button,
.button {
  border: 0;
  outline: 0;
  background: none;

  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;

  /* border-radius: 10px; */
  letter-spacing: 0px;

  width: 100%;

  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;

  transition: all var(--animation-duration) ease;
}

button.icon-right {
  flex-direction: row;
}

button.icon-left {
  flex-direction: row-reverse;
}

button.large {
  height: 40px;
  border-radius: 12px;
  gap: var(--spacing-unit);
  /* padding-block: 2px 0; */
  padding-inline: calc(var(--spacing-unit) * 2);
}

button.medium {
  height: 32px;
  border-radius: var(--spacing-unit);
  gap: calc(var(--spacing-unit) - 2px);
  /* padding-block: 2px 0; */
  padding-inline: calc(var(--spacing-unit) + 4px);
}

button.small,
.button.small {
  height: 24px;
  border-radius: calc(var(--spacing-unit) / 2);
  gap: calc(var(--spacing-unit) / 2);
  /* padding-block: 2px 0; */
  padding-inline: calc(var(--spacing-unit) + 2px);
}

button.primary {
  color: var(--white);
  background-color: var(--primary--primary-500);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2), inset 0 -2px 1px rgba(0, 0, 0, 0.2);
  /* Inner shadow */
}

button.primary svg * {
  fill: var(--icon-on-primary);
}

button.primary:hover {
  background-color: var(--primary--primary-600);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2), inset 0 -3px 1px rgba(0, 0, 0, 0.2);
}

button.primary:active {
  background-color: var(--primary--primary-700);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2), inset 0 -1px 1px rgba(0, 0, 0, 0.2);
}

button.primary:disabled {
  cursor: not-allowed;
  box-shadow: none;
  color: var(--text-disabled);
  background: var(--button-disabled);
}

button.ghost,
.button.ghost {
  color: var(--text-neutral);
  box-shadow: none;
}

button.ghost:hover,
.button.ghost:hover {
  background: var(--button-neutral-hover-clicked);
  color: var(--text-default);
  box-shadow: none;
}

button.ghost:active {
  background: var(--button-neutral-hover-clicked);
  color: var(--text-default);
  box-shadow: none;
}

button.ghost:disabled {
  cursor: not-allowed;
  color: var(--text-disabled);
  background-color: none;
  box-shadow: none;
}

button.neutral {
  color: var(--text-default);
  border: 1px solid var(--border-input-default);
  background-color: var(--button-neutral);
  box-shadow: 0px 4px 4px 0px rgba(7, 7, 7, 0.06),
    0px -3px 1px 0px rgba(7, 7, 7, 0.07) inset;
}

button.neutral:hover {
  background: var(--button-neutral-hover-clicked);
  box-shadow: 0px 4px 4px 0px rgba(7, 7, 7, 0.1),
    0px -3px 1px 0px rgba(7, 7, 7, 0.1) inset;
}

button.neutral:active {
  background: var(--button-neutral-hover-clicked);
  box-shadow: 0px 4px 4px 0px rgba(7, 7, 7, 0.03),
    0px -2px 1px 0px rgba(7, 7, 7, 0.1) inset;
}

button.neutral:disabled {
  cursor: not-allowed;
  border: 1px solid var(--border-input-default);
  background: var(--button-disabled);
  box-shadow: none;
}

button.destructive {
  color: var(--text-on-primary);
  background: var(--color-negative-500);
  /* Buttons/Primary D */
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.2),
    0px -2px 1px 0px rgba(0, 0, 0, 0.2) inset;
}

button.destructive svg * {
  fill: var(--icon-on-primary);
}

button.destructive:hover {
  background: var(--negative--400);
  /* Buttons/Primary H */
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.25),
    0px 4px 10px 0px rgba(0, 0, 0, 0.2),
    0px -3px 1px 0px rgba(0, 0, 0, 0.3) inset;
}

button.destructive:active {
  background: var(--negative--700);
  /* Buttons/Primary C */
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.25),
    0px 4px 10px 0px rgba(0, 0, 0, 0.2),
    0px -1px 1px 0px rgba(0, 0, 0, 0.3) inset;
}

button.destructive:disabled {
  cursor: not-allowed;
  color: var(--text-disabled);
  border: 1px solid var(--border-input-default);
  background: var(--button-disabled);
  box-shadow: none;
}

/* Avatar.tsx */

.avatar_wrap {
  width: var(--size) !important;
  height: var(--size) !important;
  overflow: hidden !important;
  border-radius: 50%;
}

/* default Avatar */

.logo_avatar {
  border-radius: 50%;
  border: 1px solid transparent;
  background: var(--button-neutral);
}

.upload-label .image-overlay {
  transform: translate(-50%, -50%);
  transition: all 0.3s ease;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s ease;
}

.image-uploader:hover .image-overlay {
  opacity: 1;
  pointer-events: all;
}

/* Input.tsx */

.input-wrap label {
  color: var(--text-default);
}

input.input,
textarea.input {
  height: 40px;
  padding: 16px;
  border-radius: 8px;
  font-size: var(--text-14);
  outline: none;
  color: var(--text-default);
  border: 1px solid var(--border-input-default);
  background-color: var(--backgrounds-on-canvas);
  transition: all var(--animation-duration) ease;
}

.textarea-editor {
  border-radius: 12px;
  font-size: var(--text-14);
  outline: none;
  color: var(--text-default);
  background-color: var(--backgrounds-default) !important;
  transition: all var(--animation-duration) ease;
  overflow-y: auto;
  max-height: 185px;
  /* #reminder */
}

input.otp-input.input {
  height: 64px;
  font-size: var(--text-34);
}

input.input::placeholder,
textarea.input::placeholder {
  color: var(--text-disabled);
  font-size: var(--text-14);
  line-height: 21px;
  letter-spacing: 0.1px;
  font-weight: 400;
  text-transform: capitalize;
}

input.input:hover,
textarea.input:hover {
  border: 1px solid var(--border-input-active);
}

input.input:focus,
textarea.input:focus {
  border: 1px solid var(--border-input-active);
  /* Input field/Active */
  box-shadow: 0px 0px 0px 2px rgba(110, 102, 203, 0.3);
}

input.input.error,
textarea.input.error {
  border: 1px solid var(--border-input-nagative);
  box-shadow: none;
}

input.input.error + p.feedback {
  color: var(--text-negative);
}

input.input.success + p.feedback {
  color: var(--text-success);
}

/* DropGroup.tsx */

.drop-group {
  border-radius: 8px;
  border: 1px solid var(--border-on-bg);
  background: var(--backgrounds-dropdowns);
  box-shadow: 0px 4px 8px 0px rgba(89, 89, 89, 0.1);
}

.drop-group .drop-title {
  color: var(--text-disabled);
  line-height: 18px;
  /* 150% */
  letter-spacing: 0.1px;
}

.drop-item {
  padding-block: 5.5px;
  transition: all 0.2s ease-in;
}

.drop-item:hover {
  background: var(--backgrounds-hover-clicked);
}

.drop-item:hover svg * {
  fill: var(--icon-hover);
}

.drop-item.snippet .item .title {
  color: var(--text-default);
}

.drop-item.no-snippet .item .title {
  color: var(--text-neutral);
}

.drop-item .item .snippet {
  color: var(--text-neutral);
}

.drop-item:hover .title,
.drop-item:hover .item .title {
  color: var(--text-on-bg) !important;
}

input.dropdown-input {
  border: none;
  color: var(--text-default);
}

input.dropdown-input::placeholder {
  color: var(--text-disabled);
}

/* ToggleSwitch.tsx */

input.toggleswitch[type='checkbox'] {
  height: 0;
  width: 0;
  visibility: hidden;
}

label.toggleLabel {
  cursor: pointer;
  text-indent: -9999px;
  width: 36px;
  height: 22px;
  display: block;
  border-radius: 100px;
  position: relative;
  transition: 0.3s;
  background: var(--backgrounds-hover-clicked);
  /* Inner shadow. */
  box-shadow: 0px 1px 4px 0px rgba(101, 96, 123, 0.05) inset;
}

label.toggleLabel:after {
  content: '';
  position: absolute;
  top: 3px;
  left: 3px;
  width: 16px;
  height: 16px;
  background: #fff;
  border-radius: 90px;
  transition: 0.3s;
}

input.toggleswitch:checked + label {
  background: var(--color-primary-500);
}

input:checked + label:after {
  left: calc(100% - 3px);
  transform: translateX(-100%);
}

label:active:after {
  width: 26px;
}

input:checked:disabled + label {
  background: var(--backgrounds-hover-clicked) !important;
  opacity: 0.6;
  cursor: not-allowed;
}

/* Modal.tsx */

.modal-wrapper {
  border-radius: 12px;
  border: 1px solid var(--border-on-bg);
  background: var(--backgrounds-dropdowns);

  /* Shadow/Shadow__XLarge */
  box-shadow: 0px 20px 25px 0px rgba(42, 42, 42, 0.2);
}

.modal_overlay {
  opacity: 0.5;
  background: var(--backgrounds-blanket);
}

/* Checkbox.tsx */

label svg rect.disabled {
  fill: var(--backgrounds-on-canvas);
  stroke: var(--border-default);
}

label svg rect.default {
  fill: var(--backgrounds-on-canvas);
  stroke: var(--border-input-default);
}

/* InfoSwitchCard.tsx */

.InfoSwitchCard p.title {
  color: var(--text-default);
}

.InfoSwitchCard p.snippet {
  color: var(--text-neutral);
}

/* linked.tsx */
.link-input {
  color: #8d8d8d;
}

/* IconButton.tsx */

.icon_button {
  transition: all 0.3s ease;
}

.icon_button.outline {
  border: 1px solid var(--border-on-bg) !important;
}

.icon_button.outline:hover {
  border-color: transparent !important;
}

.icon_button.micro {
  width: 24px;
  height: 24px;
}

.icon_button.micro svg {
  width: 16px;
  height: 16px;
}

.icon_button.small {
  width: 24px;
  height: 24px;
}

.icon_button.small svg {
  width: 16px;
  height: 16px;
}

.icon_button.standard {
  width: 32px;
  height: 32px;
}

.icon_button.standard svg {
  width: 20px;
  height: 20px;
}

.icon_button.large {
  width: 40px;
  height: 40px;
}

.icon_button.large svg {
  width: 24px;
  height: 24px;
}

.icon_button.ghost:hover {
  background: var(--button-neutral-hover-clicked) !important;
  /* color: var(--icon-hover); */
}

/* .icon_button.ghost svg * {
  fill: currentColor;
} */

.icon_button.ghost:hover svg * {
  fill: var(--icon-hover);
}

.icon_button.ghost.open,
.icon_button.ghost:active {
  background: var(--button-neutral-hover-clicked);
}

.icon_button.ghost.open svg *,
.icon_button.ghost:active svg * {
  fill: var(--icon-hover) !important;
}

.icon_button.ghost.disabled {
  background: none;
}

.icon_button.ghost.disabled svg * {
  fill: var(--icon-neutral);
}

.icon_button.neutral {
  border: 1px solid var(--border-input-default);
  background: var(--button-neutral);
  /* Buttons/Neutral D */
  box-shadow: 0px 4px 4px 0px rgba(7, 7, 7, 0.06),
    0px -3px 1px 0px rgba(7, 7, 7, 0.07) inset;
}

.icon_button.neutral:hover {
  border: 1px solid var(--border-input-default);
  /* Buttons/Neutral H */
  box-shadow: 0px 4px 4px 0px rgba(7, 7, 7, 0.1),
    0px -3px 1px 0px rgba(7, 7, 7, 0.1) inset;
}

.icon_button.neutral:hover svg * {
  background: var(--button-neutral);
  fill: var(--icon-hover);
}

.icon_button.neutral.open,
.icon_button.neutral:active {
  border: 1px solid var(--border-input-default);
  background: var(--button-neutral-hover-clicked);
  /* Buttons/Neutral C */
  box-shadow: 0px 4px 4px 0px rgba(7, 7, 7, 0.03),
    0px -2px 1px 0px rgba(7, 7, 7, 0.1) inset;
}

.icon_button.neutral.open svg *,
.icon_button.neutral:active svg * {
  fill: var(--icon-hover);
}

.icon_button.neutral.disabled {
  background: none;
  box-shadow: none;
  border: 1px solid var(--border-input-default);
}

.icon_button.neutral.disabled svg * {
  fill: var(--icon-neutral);
}

.icon_button.primary {
  background: var(--button-primary);
  /* Buttons/Primary D */
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.2),
    0px -2px 1px 0px rgba(0, 0, 0, 0.2) inset;
}

.icon_button.primary svg * {
  fill: var(--icon-on-primary);
}

.icon_button.primary:hover {
  background: var(--button-primary-hover-clicked);

  /* Buttons/Primary H */
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.25),
    0px 4px 10px 0px rgba(0, 0, 0, 0.2),
    0px -3px 1px 0px rgba(0, 0, 0, 0.3) inset;
}

.icon_button.primary.open,
.icon_button.primary:active {
  background: var(--button-primary-hover-clicked);

  /* Buttons/Primary C */
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.25),
    0px 4px 10px 0px rgba(0, 0, 0, 0.2),
    0px -1px 1px 0px rgba(0, 0, 0, 0.3) inset;
}

.icon_button.primary.disabled {
  border: none;
  box-shadow: none;
  background: var(--button-disabled);
}

.icon_button.primary.disabled svg * {
  fill: var(--icon-neutral);
}

/* TableLayout.tsx */
.table_layout {
  display: grid;
  gap: 0;
  grid-template-columns: 7fr 3fr 2fr;
  height: 60px;
  border-bottom: 1px solid var(--border-on-bg);
}

/* svg */
svg.fill-neutral {
  fill: var(--icon-neutral);
}

/* Badge.tsx */

.badge {
  display: inline-flex;
  height: 14px;
  padding: 4px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  color: var(--backgrounds-canvas);
  background: var(--color-grey-500);
}

/* select.tsx */

.select_container {
  border: 1px solid var(--border-input-default);
  background: var(--backgrounds-on-canvas);
}

.select_container:hover {
  border: 1px solid var(--border-input-active);
}

.select_container.focus {
  border: 1.5px solid var(--border-input-active);
  background: var(--backgrounds-on-canvas);

  /* Input field/Active */
  box-shadow: 0px 0px 0px 2px rgba(110, 102, 203, 0.3);
}

/* colorTags.tsx */
.color-tag svg * {
  fill: var(--fill);
}

/* statusTags.tsx */
.status-tag svg * {
  fill: var(--fill);
}

/* links.tsx */

.link-icon-container {
  transition: transform 0.3s ease-in-out;
}

.link-icon-container > * {
  display: inline-block;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.link-icon-container > *:first-child {
  transform: scale(var(--scale-factor, 1));
}

/* ImageViewer.tsx */

button svg.close_icon * {
  fill: white;
}

/* SideNavMobile.tsx */

/* SideNavMobileBtn */

.SideNavMobileBtn.active.exact-active svg path {
  fill: var(--icon-hover);
}

.SideNavMobileBtn.active.exact-active p {
  color: var(--text-default);
}

/* PageLoader.tsx */
@keyframes page_loading_frame {
  0% {
    left: 0;
    width: 0px;
  }

  50% {
    left: 48px;
    width: 48px;
  }

  100% {
    left: 96px;
    width: 0px;
  }
}

.page_loading_bar {
  position: relative;
  width: 96px;
  min-height: 2px;
  overflow: hidden;
  border-radius: 8px;
}

.page_loading_bar::before {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 96px;
  min-height: 2px;
  background-color: var(--backgrounds-default);
}

.page_loading_bar::after {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 10px;
  min-height: 2px;
  background-color: var(--icon-primary);
  animation: page_loading_frame 1s linear infinite;
}

.slide-in-panel {
  transform: translateX(0%);
}

.close-panel {
  transform: translateX(100%);
}

@media (min-width: 768px) {
  .header_text .subtitle {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 150% */
  }

  .header_text .title {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 27px;
    /* 150% */
  }
}
