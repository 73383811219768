.side_nav_wrapper {
  position: relative;
  user-select: none;
  padding: 32px 8px 32px 16px;
  background: var(--backgrounds-canvas);
}

.sidebar_nav_content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
}

.side_nav_group {
  cursor: default;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  white-space: nowrap;
}

.side__menu__btn {
  display: flex;
  height: 32px;
  padding: 2px 12px;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  width: 100%;
  /* max-width: 200px; */
}

.side__menu__btn:hover {
  border-radius: 8px;
  background: var(--backgrounds-hover-clicked);
}

.icon_label {
  display: flex;
  align-items: center;
  gap: 8px;
}

.icon_label span.label {
  color: var(--text-neutral);
}

p.nav_title {
  color: var(--text-disabled);
  padding-inline: calc(var(--spacing-unit) + 4px);
}

.menu_btn_group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

.menu_btn_group a {
  width: 100%;
}

.side__menu__btn.active.exact-active {
  background: var(--backgrounds-hover-clicked);
}

.side__menu__btn.active.exact-active .icon_label span.label {
  color: var(--text-on-bg);
}

.side__menu__btn.active.exact-active .icon_label svg * {
  fill: var(--icon-hover);
}

.resizer {
  width: 4px;
  top: 0;
  right: 0;
  cursor: col-resize;
  height: 100%;
  position: absolute;
  background-color: transparent;
}
