/* settings/workspace/General.tsx */
.workspace-page {
    max-width: 680px;
    width: 100%;
}

.workspace-page .header_div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
}

.workspace-page .section-wrap {
    border-radius: 12px;
    border: 1px solid var(--border-on-bg);
    background: var(--backgrounds-default);
}

.workspace-page .section-wrap p.title {
    color: var(--text-on-bg);
}

.workspace-page .section-wrap p.snippet {
    color: var(--text-neutral);
}

.general-logo-p {
    color: var(--text-neutral);
}

.workspace-page p.notification-title {
    color: var(--text-default);
    font-weight: 500;
}