.ispinner {
  position: relative;
  width: 20px;
  height: 20px;
}

.ispinner .ispinner_blade {
  position: absolute;
  top: 6.5px;
  left: 8.5px;
  width: 2.5px;
  height: 6.5px;
  border-radius: 1.25px;
  -webkit-animation: iSpinnerBlade 1s linear infinite;
  animation: iSpinnerBlade 1s linear infinite;
  will-change: opacity;
}

.ispinner .ispinner_blade:nth-child(1) {
  -webkit-transform: rotate(45deg) translateY(-6.5px);
  transform: rotate(45deg) translateY(-6.5px);
  -webkit-animation-delay: -1.625s;
  animation-delay: -1.625s;
}

.ispinner .ispinner_blade:nth-child(2) {
  -webkit-transform: rotate(90deg) translateY(-6.5px);
  transform: rotate(90deg) translateY(-6.5px);
  -webkit-animation-delay: -1.5s;
  animation-delay: -1.5s;
}

.ispinner .ispinner_blade:nth-child(3) {
  -webkit-transform: rotate(135deg) translateY(-6.5px);
  transform: rotate(135deg) translateY(-6.5px);
  -webkit-animation-delay: -1.375s;
  animation-delay: -1.375s;
}

.ispinner .ispinner_blade:nth-child(4) {
  -webkit-transform: rotate(180deg) translateY(-6.5px);
  transform: rotate(180deg) translateY(-6.5px);
  -webkit-animation-delay: -1.25s;
  animation-delay: -1.25s;
}

.ispinner .ispinner_blade:nth-child(5) {
  -webkit-transform: rotate(225deg) translateY(-6.5px);
  transform: rotate(225deg) translateY(-6.5px);
  -webkit-animation-delay: -1.125s;
  animation-delay: -1.125s;
}

.ispinner .ispinner_blade:nth-child(6) {
  -webkit-transform: rotate(270deg) translateY(-6.5px);
  transform: rotate(270deg) translateY(-6.5px);
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.ispinner .ispinner_blade:nth-child(7) {
  -webkit-transform: rotate(315deg) translateY(-6.5px);
  transform: rotate(315deg) translateY(-6.5px);
  -webkit-animation-delay: -0.875s;
  animation-delay: -0.875s;
}

.ispinner .ispinner_blade:nth-child(8) {
  -webkit-transform: rotate(360deg) translateY(-6.5px);
  transform: rotate(360deg) translateY(-6.5px);
  -webkit-animation-delay: -0.75s;
  animation-delay: -0.75s;
}

.ispinner.ispinner-large {
  width: 35px;
  height: 35px;
}

.ispinner.ispinner-large .ispinner_blade {
  top: 11.5px;
  left: 15px;
  width: 5px;
  height: 12px;
  border-radius: 2.5px;
}

.ispinner.ispinner-large .ispinner_blade:nth-child(1) {
  -webkit-transform: rotate(45deg) translateY(-11.5px);
  transform: rotate(45deg) translateY(-11.5px);
}

.ispinner.ispinner-large .ispinner_blade:nth-child(2) {
  -webkit-transform: rotate(90deg) translateY(-11.5px);
  transform: rotate(90deg) translateY(-11.5px);
}

.ispinner.ispinner-large .ispinner_blade:nth-child(3) {
  -webkit-transform: rotate(135deg) translateY(-11.5px);
  transform: rotate(135deg) translateY(-11.5px);
}

.ispinner.ispinner-large .ispinner_blade:nth-child(4) {
  -webkit-transform: rotate(180deg) translateY(-11.5px);
  transform: rotate(180deg) translateY(-11.5px);
}

.ispinner.ispinner-large .ispinner_blade:nth-child(5) {
  -webkit-transform: rotate(225deg) translateY(-11.5px);
  transform: rotate(225deg) translateY(-11.5px);
}

.ispinner.ispinner-large .ispinner_blade:nth-child(6) {
  -webkit-transform: rotate(270deg) translateY(-11.5px);
  transform: rotate(270deg) translateY(-11.5px);
}

.ispinner.ispinner-large .ispinner_blade:nth-child(7) {
  -webkit-transform: rotate(315deg) translateY(-11.5px);
  transform: rotate(315deg) translateY(-11.5px);
}

.ispinner.ispinner-large .ispinner_blade:nth-child(8) {
  -webkit-transform: rotate(360deg) translateY(-11.5px);
  transform: rotate(360deg) translateY(-11.5px);
}

@-webkit-keyframes iSpinnerBlade {
  0% {
    opacity: 0.85;
  }

  50% {
    opacity: 0.25;
  }

  100% {
    opacity: 0.25;
  }
}

@keyframes iSpinnerBlade {
  0% {
    opacity: 0.85;
  }

  50% {
    opacity: 0.25;
  }

  100% {
    opacity: 0.25;
  }
}
