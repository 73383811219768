@import url('non.geist');

:root {
  --spacing-unit: 8px;
  --animation-duration: 300ms;
  --white: rgba(255, 255, 255, 1);
  /* GREY */
  --grey--grey-25: rgba(248, 248, 249, 1);
  --grey--grey-50: rgba(242, 242, 245, 1);
  --grey--grey-75: rgba(234, 233, 237, 1);
  --grey--grey-100: rgba(196, 195, 205, 1);
  --grey--grey-200: rgba(150, 147, 165, 1);
  --grey--grey-300: rgba(101, 96, 123, 1);
  --grey--grey-400: rgba(55, 48, 83, 1);
  --grey--grey-500: rgba(11, 3, 45, 1);
  --grey--grey-600: rgba(9, 3, 38, 1);
  --grey--grey-700: rgba(8, 2, 32, 1);
  --grey--grey-800: rgba(6, 2, 26, 1);
  --grey--grey-900: rgba(5, 1, 20, 1);
  /* PRIMARY */
  --primary--primary-25: rgba(248, 248, 254, 1);
  --primary--primary-50: rgba(242, 242, 253, 1);
  --primary--primary-100: rgba(225, 223, 251, 1);
  --primary--primary-200: rgba(201, 197, 248, 1);
  --primary--primary-300: rgba(176, 170, 245, 1);
  --primary--primary-400: rgba(152, 144, 242, 1);
  --primary--primary-500: rgba(129, 120, 239, 1);
  --primary--primary-600: rgba(110, 102, 203, 1);
  --primary--primary-700: rgba(92, 85, 170, 1);
  --primary--primary-800: rgba(74, 68, 136, 1);
  --primary--primary-900: rgba(58, 54, 108, 1);
  /* BLUE */
  --blue--blue-25: rgba(244, 247, 255, 1);
  --blue--blue-50: rgba(234, 240, 255, 1);
  --blue--blue-100: rgba(190, 208, 255, 1);
  --blue--blue-200: rgba(158, 185, 255, 1);
  --blue--blue-300: rgba(114, 152, 255, 1);
  --blue--blue-400: rgba(86, 133, 255, 1);
  --blue--blue-500: rgba(44, 102, 255, 1);
  --blue--blue-600: rgba(40, 93, 232, 1);
  --blue--blue-700: rgba(31, 72, 181, 1);
  --blue--blue-800: rgba(24, 56, 140, 1);
  --blue--blue-900: rgba(18, 43, 107, 1);
  /* NEGATIVE */
  --negative--50: rgba(251, 234, 233, 1);
  --negative--75: rgba(242, 188, 186, 1);
  --negative--100: rgba(235, 155, 152, 1);
  --negative--200: rgba(226, 110, 106, 1);
  --negative--300: rgba(221, 82, 77, 1);
  --negative--400: rgba(212, 38, 32, 1);
  --negative--500-base: rgba(203, 26, 20, 1);
  --negative--600: rgba(186, 17, 11, 1);
  --negative--700: rgba(158, 10, 5, 1);
  --negative--800: rgba(128, 5, 1, 1);
  --negative--900: rgba(89, 16, 0, 1);
  /* SUCCESS */
  --success--50: rgba(231, 246, 236, 1);
  --success--75: rgba(181, 227, 196, 1);
  --success--100: rgba(145, 214, 168, 1);
  --success--200: rgba(95, 195, 129, 1);
  --success--300: rgba(64, 184, 105, 1);
  --success--400: rgba(15, 151, 61, 1);
  --success--500-base: rgba(9, 145, 55, 1);
  --success--600: rgba(4, 128, 46, 1);
  --success--700: rgba(3, 107, 38, 1);
  --success--800: rgba(1, 91, 32, 1);
  --success--900: rgba(0, 70, 23, 1);
  /* WARNING */
  --warning--50: rgba(254, 246, 231, 1);
  --warning--75: rgba(251, 226, 183, 1);
  --warning--100: rgba(247, 211, 148, 1);
  --warning--200: rgba(247, 193, 100, 1);
  --warning--300: rgba(245, 181, 70, 1);
  --warning--400: rgba(243, 162, 24, 1);
  --warning--500-base: rgba(221, 144, 13, 1);
  --warning--600: rgba(173, 111, 7, 1);
  --warning--700: rgba(134, 85, 3, 1);
  --warning--800: rgba(102, 65, 1, 1);
  --warning--900: rgba(82, 51, 0, 1);
  /* AVATAR */
  --avatar-blue-gem-surface: rgba(223, 221, 255, 1);
  --avatar-blue-gem-text-amp-icon: rgba(68, 9, 185, 1);

  --avatar-oxford-blue-surface: rgba(208, 224, 237, 1);
  --avatar-oxford-blue-text-amp-icon: rgba(45, 58, 70, 1);

  --avatar-willow-grove-surface: rgba(220, 233, 209, 1);
  --avatar-willow-grove-text-amp-icon: rgba(105, 120, 94, 1);

  --avatar-persian-indigo-surface: rgba(228, 226, 243, 1);
  --avatar-persian-indigo-text-amp-icon: rgba(40, 15, 109, 1);

  --avatar-meteor-surface: rgba(253, 239, 226, 1);
  --avatar-meteor-text-amp-icon: rgba(197, 101, 17, 1);

  --avatar-korma-surface: rgba(254, 239, 196, 1);
  --avatar-korma-text-amp-icon: rgba(147, 95, 16, 1);

  --avatar-honey-flower-surface: rgba(230, 235, 239, 1);
  --avatar-honey-flower-text-amp-icon: rgba(77, 23, 110, 1);

  --avatar-green-leaf-surface: rgba(242, 255, 209, 1);
  --avatar-green-leaf-text-amp-icon: rgba(82, 110, 12, 1);

  --avatar-martinique-surface: rgba(230, 223, 236, 1);
  --avatar-martinique-text-amp-icon: rgba(55, 54, 79, 1);

  --avatar-cloud-burst-surface: rgba(216, 232, 243, 1);
  --avatar-cloud-burst-text-amp-icon: rgba(34, 42, 84, 1);

  --avatar-ultramarine-surface: rgba(237, 238, 253, 1);
  --avatar-ultramarine-text-amp-icon: rgba(5, 18, 138, 1);

  --avatar-whisper-surface: rgba(235, 230, 239, 1);
  --avatar-whisper-text-amp-icon: rgba(171, 19, 62, 1);

  --avatar-eastern-blue-surface: rgba(203, 237, 247, 1);
  --avatar-eastern-blue-text-amp-icon: rgba(31, 132, 163, 1);

  --avatar-tuna-surface: rgba(226, 244, 232, 1);
  --avatar-tuna-text-amp-icon: rgba(54, 53, 72, 1);

  --avatar-bunting-surface: rgba(235, 224, 254, 1);
  --avatar-bunting-text-amp-icon: rgba(25, 34, 81, 1);

  --avatar-green-pea-surface: rgba(194, 243, 226, 1);
  --avatar-green-pea-text-amp-icon: rgba(33, 110, 85, 1);

  --avatar-kingfisher-daisy-surface: rgba(233, 220, 252, 1);
  --avatar-kingfisher-daisy-text-amp-icon: rgba(66, 7, 144, 1);

  --avatar-shiraz-surface: rgba(255, 207, 214, 1);
  --avatar-shiraz-text-amp-icon: rgba(189, 15, 44, 1);

  --avatar-rouge-surface: rgba(255, 216, 235, 1);
  --avatar-rouge-text-amp-icon: rgba(151, 53, 98, 1);
  /* colors theme */
  --color-a-100: rgb(194, 243, 226);
  --color-a-700: rgb(33, 110, 85);
  --color-b-100: rgb(203, 250, 242);
  --color-b-700: rgb(12, 117, 110);
  --color-c-100: rgb(255, 237, 213);
  --color-c-700: rgb(194, 63, 9);
  --color-d-100: rgb(242, 255, 209);
  --color-d-700: rgb(82, 110, 12);
  --color-e-100: rgb(236, 233, 254);
  --color-e-700: rgb(109, 40, 216);
  --color-f-100: rgb(254, 249, 194);
  --color-f-700: rgb(184, 87, 11);
  --color-g-100: rgb(230, 223, 236);
  --color-g-700: rgb(55, 54, 79);
  --color-grey-00: rgb(255, 255, 255);
  --color-grey-100: rgb(178, 178, 178);
  --color-grey-200: rgb(141, 141, 141);
  --color-grey-25: rgb(245, 245, 245);
  --color-grey-300: rgb(89, 89, 89);
  --color-grey-400: rgb(42, 42, 42);
  --color-grey-50: rgb(230, 230, 230);
  --color-grey-35: rgb(240, 240, 240);
  --color-grey-500: rgb(7, 7, 7);
  --color-grey-600: rgb(18, 18, 18);
  --color-grey-700: rgb(9, 9, 9);
  --color-grey-75: rgb(216, 216, 216);
  --color-grey-800: #212121;
  --color-grey-900: rgb(61, 61, 61);
  --color-h-100: rgb(255, 216, 235);
  --color-h-700: rgb(151, 53, 98);
  --color-negative-100: rgb(249, 221, 221);
  --color-negative-200: rgb(226, 110, 106);
  --color-negative-500: rgb(203, 26, 20);
  --color-negative-700: rgb(158, 10, 5);
  --color-positive-100: rgb(216, 227, 255);
  --color-positive-200: rgb(158, 185, 255);
  --color-positive-500: rgb(44, 102, 255);
  --color-positive-700: rgb(31, 72, 181);
  --color-primary-100: rgb(225, 223, 251);
  --color-primary-400: rgb(152, 144, 242);
  --color-primary-500: rgb(129, 120, 239);
  --color-primary-600: rgb(110, 102, 203);
  --color-primary-700: rgb(92, 85, 170);
  --color-success-100: rgb(218, 241, 226);
  --color-success-200: rgb(95, 195, 129);
  --color-success-500: rgb(9, 145, 55);
  --color-success-700: rgb(3, 107, 38);
  --color-warning-100: rgb(253, 238, 212);
  --color-warning-200: rgb(247, 193, 100);
  --color-warning-500: rgb(221, 144, 13);
  --color-warning-700: rgb(134, 85, 3);
}

[data-theme='light'] {
  /* colors */
  --backgrounds-blanket: var(--color-grey-500);
  --backgrounds-canvas: #f9f9f9;
  --backgrounds-default: var(--color-grey-25);
  --backgrounds-dropdowns: var(--color-grey-00);
  --backgrounds-hover-clicked: var(--color-grey-35);
  --backgrounds-message: var(--color-primary-100);
  --backgrounds-nagative: var(--color-negative-100);
  --backgrounds-on-bg: var(--color-grey-00);
  --backgrounds-scroll-edge: linear-gradient(
    0deg,
    rgba(18, 18, 18, 0) 0%,
    #121212 100%
  );
  --backgrounds-on-canvas: var(--color-grey-00);
  --backgrounds-positive: var(--color-positive-100);
  --backgrounds-success: var(--color-success-100);
  --backgrounds-warning: var(--color-warning-100);
  --border-default: var(--color-grey-25);
  --border-divider: var(--color-grey-35);
  --border-input-active: var(--color-primary-500);
  --border-input-default: var(--color-grey-50);
  --border-input-hover: var(--color-grey-75);
  --border-input-nagative: var(--color-negative-700);
  --border-on-bg: var(--color-grey-50);
  --button-destructive: var(--color-negative-700);
  --button-disabled: var(--color-grey-50);
  --button-neutral: var(--color-grey-00);
  --button-neutral-hover-clicked: var(--color-grey-50);
  --button-primary: var(--color-primary-500);
  --button-primary-hover-clicked: var(--color-primary-600);
  --icon-hover: var(--color-grey-500);
  --icon-negative: var(--color-negative-700);
  --icon-neutral: var(--color-grey-100);
  --icon-on-primary: var(--color-grey-00);
  --icon-positive: var(--color-positive-700);
  --icon-primary: var(--color-grey-200);
  --icon-success: var(--color-success-700);
  --icon-warning: var(--color-warning-700);
  --tags-a-bg: var(--color-a-100);
  --tags-a-text: var(--color-a-700);
  --tags-b-bg: var(--color-b-100);
  --tags-b-text: var(--color-b-700);
  --tags-c-bg: var(--color-c-100);
  --tags-c-text: var(--color-c-700);
  --tags-d-bg: var(--color-d-100);
  --tags-d-text: var(--color-d-700);
  --tags-e-bg: var(--color-e-100);
  --tags-e-text: var(--color-e-700);
  --tags-f-bg: var(--color-f-100);
  --tags-f-text: var(--color-f-700);
  --tags-g-bg: var(--color-g-100);
  --tags-g-text: var(--color-g-700);
  --tags-h-bg: var(--color-h-100);
  --tags-h-text: var(--color-h-700);
  --text-default: var(--color-grey-500);
  --text-disabled: var(--color-grey-200);
  --text-negative: var(--color-negative-700);
  --text-neutral: var(--color-grey-300);
  --text-on-bg: var(--color-grey-400);
  --text-on-primary: var(--color-grey-00);
  --text-positive: var(--color-positive-700);
  --text-success: var(--color-success-700);
  --text-warning: var(--color-warning-700);
  --mentions-bg: #e1dffb;
  --mentions-text: var(--color-primary-500);
  --truncate-bg: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
  --scroll-bg-blur: linear-gradient(0deg, #ffffff 0%, rgba(18, 18, 18, 0) 100%);
  --get-started-section: linear-gradient(
    284.27deg,
    rgba(255, 216, 235, 0.5) 17.62%,
    rgba(254, 249, 194, 0.5) 53.98%,
    rgba(230, 223, 236, 0.5) 80.16%
  );
  --get-started-blend-bg: linear-gradient(
    270deg,
    rgba(245, 245, 245, 0) 0%,
    #f5f5f5 100%
  );
  --plan-card-bg: linear-gradient(
    284.27deg,
    rgba(255, 207, 214, 0.2) 17.62%,
    rgba(254, 239, 196, 0.2) 53.98%,
    rgba(216, 232, 243, 0.2) 80.16%
  );
  --skeleton-bg: linear-gradient(90deg, #e6e6e6 0%, #f5f5f5 100%);
}

/* background: linear-gradient(119.76deg, #E56B5D 0.79%, #8178EF 97.24%); */
/* background: linear-gradient(119.76deg, #E56B5D 0.79%, #8178EF 97.24%); */

[data-theme='dark'] {
  /* colors */
  --backgrounds-blanket: var(--color-grey-500);
  --backgrounds-canvas: var(--color-grey-700);
  --backgrounds-default: var(--color-grey-400);
  --backgrounds-dropdowns: var(--color-grey-800);
  --backgrounds-hover-clicked: var(--color-grey-400);
  --backgrounds-message: var(--color-primary-600);
  --backgrounds-nagative: var(--color-negative-700);
  --backgrounds-on-bg: var(--color-grey-300);
  --backgrounds-scroll-edge: linear-gradient(
    0deg,
    rgba(18, 18, 18, 0) 0%,
    #121212 100%
  );
  --backgrounds-on-canvas: var(--color-grey-600);
  --backgrounds-positive: var(--color-positive-700);
  --backgrounds-success: var(--color-success-700);
  --backgrounds-warning: var(--color-warning-700);
  --border-default: var(--color-grey-800);
  --border-divider: var(--color-grey-400);
  --border-input-active: var(--color-primary-500);
  --border-input-default: var(--color-grey-400);
  --border-input-hover: var(--color-grey-800);
  --border-input-nagative: var(--color-negative-100);
  --border-on-bg: var(--color-grey-400);
  --button-destructive: var(--color-negative-500);
  --button-disabled: var(--color-grey-400);
  --button-neutral: var(--color-grey-900);
  --button-neutral-hover-clicked: var(--color-grey-300);
  --button-primary: var(--color-primary-500);
  --button-primary-hover-clicked: var(--color-primary-400);
  --icon-hover: var(--color-grey-25);
  --icon-negative: var(--color-negative-100);
  --icon-neutral: var(--color-grey-300);
  --icon-on-primary: var(--color-grey-00);
  --icon-positive: var(--color-positive-100);
  --icon-primary: var(--color-grey-200);
  --icon-success: var(--color-success-100);
  --icon-warning: var(--color-warning-100);
  --tags-a-bg: var(--color-a-700);
  --tags-a-text: var(--color-a-100);
  --tags-b-bg: var(--color-b-700);
  --tags-b-text: var(--color-b-100);
  --tags-c-bg: var(--color-c-700);
  --tags-c-text: var(--color-c-100);
  --tags-d-bg: var(--color-d-700);
  --tags-d-text: var(--color-d-100);
  --tags-e-bg: var(--color-e-700);
  --tags-e-text: var(--color-e-100);
  --tags-f-bg: var(--color-f-700);
  --tags-f-text: var(--color-f-100);
  --tags-g-bg: var(--color-g-700);
  --tags-g-text: var(--color-g-100);
  --tags-h-bg: var(--color-h-700);
  --tags-h-text: var(--color-h-100);
  --text-default: var(--color-grey-25);
  --text-disabled: var(--color-grey-200);
  --text-negative: var(--color-negative-100);
  --text-neutral: var(--color-grey-100);
  --text-on-bg: var(--color-grey-75);
  --text-on-primary: var(--color-grey-00);
  --text-positive: var(--color-positive-100);
  --text-success: var(--color-success-100);
  --text-warning: var(--color-warning-100);
  --mentions-bg: #5c55aa;
  --mentions-text: var(--color-primary-400);
  --truncate-bg: linear-gradient(180deg, rgba(18, 18, 18, 0) 0%, #121212 100%);
  --scroll-bg-blur: linear-gradient(0deg, #121212 0%, rgba(18, 18, 18, 0) 100%);
  --get-started-section: linear-gradient(
    284.27deg,
    rgba(80, 31, 54, 0.5) 17.62%,
    rgba(97, 48, 10, 0.5) 53.98%,
    rgba(32, 32, 44, 0.5) 80.16%
  );
  --get-started-blend-bg: linear-gradient(
    270deg,
    rgba(42, 42, 42, 0) 0%,
    #2a2a2a 100%
  );
  --plan-card-bg: linear-gradient(
    284.27deg,
    rgba(255, 207, 214, 0.2) 17.62%,
    rgba(254, 239, 196, 0.2) 53.98%,
    rgba(216, 232, 243, 0.2) 80.16%
  );
  --skeleton-bg: linear-gradient(90deg, #2a2a2a 0%, #212121 100%);
}

:root {
  font-family: Inter, sans-serif;
  font-feature-settings: 'liga' 1, 'calt' 1;
  /* fix for Chrome */
}

@supports (font-variation-settings: normal) {
  :root {
    font-family: InterVariable, sans-serif;
  }
}

:root {
  /* Text-size styles */
  --text-48: 48px;
  --text-34: 34px;
  --text-24: 24px;
  --text-20: 20px;
  --text-18: 18px;
  --text-16: 16px;
  --text-14: 14px;
  --text-13: 13px;
  --text-12: 12px;
  --text-10: 10px;
  --text-88: 88px;
  --text-32: 32px;
}

/* Text utility classes */

:root {
  font-family: Inter, sans-serif;
  font-feature-settings: 'liga' 1, 'calt' 1;
  /* fix for Chrome */
}

.root {
  position: fixed;
  width: 100vw;
  height: 100vh;
}

body {
  background: var(--backgrounds-canvas);
}

.bold {
  font-weight: 600;
}

.semibold {
  font-weight: 600;
}

.medium {
  font-weight: 500;
}

.regular {
  font-weight: 400;
}

@supports (font-variation-settings: normal) {
  :root {
    font-family: InterVariable, sans-serif;
  }
}

p,
p > * {
  color: var(--text-default);
  font-family: Inter;
}

a {
  color: var(--button-primary) !important;
}

.text-heading-1 {
  font-size: var(--text-48);
  line-height: calc(var(--spacing-unit) * 8);
}

.text-heading-1-small {
  font-size: var(--text-34);
  line-height: calc(var(--spacing-unit) * 5.5);
}

.text-heading-2 {
  font-size: var(--text-34);
  line-height: calc(var(--spacing-unit) * 5.5);
}

.text-heading-3 {
  font-size: var(--text-24);
  line-height: calc(var(--spacing-unit) * 4);
}

.text-heading-3-small {
  font-size: var(--text-20);
  line-height: calc(var(--spacing-unit) * 3.75);
}

.text-heading-4 {
  font-size: var(--text-20);
  line-height: calc(var(--spacing-unit) * 3.75);
}

.text-heading-5 {
  font-size: var(--text-18);
  line-height: 27px;
}

.text-heading-6 {
  font-size: var(--text-16);
  line-height: 24px;
}

.text-body-large {
  font-size: var(--text-16);
  line-height: calc(var(--spacing-unit) * 3);
  letter-spacing: 0.1px;
}

.text-body-small {
  font-size: var(--text-14) !important;
  line-height: calc(var(--spacing-unit) * 2.625) !important;
}

.text-body-mini {
  font-size: var(--text-13) !important;
  line-height: 19.5px !important;
  letter-spacing: 0.1px;
}

.text-body-micro,
.text-body-micro > * {
  font-size: var(--text-12) !important;
  letter-spacing: 0.1px;
}

.text-body-caps {
  font-size: var(--text-12) !important;
  letter-spacing: 1px;
  line-height: 21px;
  text-transform: uppercase;
}

.text-caption {
  font-size: var(--text-10);
  line-height: calc(var(--spacing-unit) + 7px);
  letter-spacing: 0.1px;
}

/* background utility class */

.bg-grey-25 {
  background-color: var(--grey--grey-25);
}

.bg-grey-50 {
  background-color: var(--grey--grey-50);
}

.bg-grey-75 {
  background-color: var(--grey--grey-75);
}

.bg-grey-100 {
  background-color: var(--grey--grey-100);
}

.bg-grey-200 {
  background-color: var(--grey--grey-200);
}

.bg-grey-300 {
  background-color: var(--grey--grey-300);
}

.bg-grey-400 {
  background-color: var(--grey--grey-400);
}

.bg-grey-500 {
  background-color: var(--grey--grey-500);
}

.bg-grey-600 {
  background-color: var(--grey--grey-600);
}

.bg-grey-700 {
  background-color: var(--grey--grey-700);
}

.bg-grey-800 {
  background-color: var(--grey--grey-800);
}

.bg-grey-900 {
  background-color: var(--grey--grey-900);
}

.bg-primary-25 {
  background-color: var(--primary--primary-25);
}

.bg-primary-50 {
  background-color: var(--primary--primary-50);
}

.bg-primary-100 {
  background-color: var(--primary--primary-100);
}

.bg-primary-200 {
  background-color: var(--primary--primary-200);
}

.bg-primary-300 {
  background-color: var(--primary--primary-300);
}

.bg-primary-400 {
  background-color: var(--primary--primary-400);
}

.bg-primary-500 {
  background-color: var(--primary--primary-500);
}

.bg-primary-600 {
  background-color: var(--primary--primary-600);
}

.bg-primary-700 {
  background-color: var(--primary--primary-700);
}

.bg-primary-800 {
  background-color: var(--primary--primary-800);
}

.bg-primary-900 {
  background-color: var(--primary--primary-900);
}

.bg-blue-25 {
  background-color: var(--blue--blue-25);
}

.bg-blue-50 {
  background-color: var(--blue--blue-50);
}

.bg-blue-100 {
  background-color: var(--blue--blue-100);
}

.bg-blue-200 {
  background-color: var(--blue--blue-200);
}

.bg-blue-300 {
  background-color: var(--blue--blue-300);
}

.bg-blue-400 {
  background-color: var(--blue--blue-400);
}

.bg-blue-500 {
  background-color: var(--blue--blue-500);
}

.bg-blue-600 {
  background-color: var(--blue--blue-600);
}

.bg-blue-700 {
  background-color: var(--blue--blue-700);
}

.bg-blue-800 {
  background-color: var(--blue--blue-800);
}

.bg-blue-900 {
  background-color: var(--blue--blue-900);
}

.bg-negative-50 {
  background-color: var(--negative--50);
}

.bg-negative-75 {
  background-color: var(--negative--75);
}

.bg-negative-100 {
  background-color: var(--negative--100);
}

.bg-negative-200 {
  background-color: var(--negative--200);
}

.bg-negative-300 {
  background-color: var(--negative--300);
}

.bg-negative-400 {
  background-color: var(--negative--400);
}

.bg-negative-500-base {
  background-color: var(--negative--500-base);
}

.bg-negative-600 {
  background-color: var(--negative--600);
}

.bg-negative-700 {
  background-color: var(--negative--700);
}

.bg-negative-800 {
  background-color: var(--negative--800);
}

.bg-negative-900 {
  background-color: var(--negative--900);
}

.bg-success-50 {
  background-color: rgba(231, 246, 236, 1);
}

.bg-success-75 {
  background-color: rgba(181, 227, 196, 1);
}

.bg-success-100 {
  background-color: rgba(145, 214, 168, 1);
}

.bg-success-200 {
  background-color: rgba(95, 195, 129, 1);
}

.bg-success-300 {
  background-color: rgba(64, 184, 105, 1);
}

.bg-success-400 {
  background-color: rgba(15, 151, 61, 1);
}

.bg-success-500-base {
  background-color: rgba(9, 145, 55, 1);
}

.bg-success-600 {
  background-color: rgba(4, 128, 46, 1);
}

.bg-success-700 {
  background-color: rgba(3, 107, 38, 1);
}

.bg-success-800 {
  background-color: rgba(1, 91, 32, 1);
}

.bg-success-900 {
  background-color: rgba(0, 70, 23, 1);
}

.bg-warning-50 {
  background-color: rgba(254, 246, 231, 1);
}

.bg-warning-75 {
  background-color: rgba(251, 226, 183, 1);
}

.bg-warning-100 {
  background-color: rgba(247, 211, 148, 1);
}

.bg-warning-200 {
  background-color: rgba(247, 193, 100, 1);
}

.bg-warning-300 {
  background-color: rgba(245, 181, 70, 1);
}

.bg-warning-400 {
  background-color: rgba(243, 162, 24, 1);
}

.bg-warning-500-base {
  background-color: rgba(221, 144, 13, 1);
}

.bg-warning-600 {
  background-color: rgba(173, 111, 7, 1);
}

.bg-warning-700 {
  background-color: rgba(134, 85, 3, 1);
}

.bg-warning-800 {
  background-color: rgba(102, 65, 1, 1);
}

.bg-warning-900 {
  background-color: rgba(82, 51, 0, 1);
}

/* avatar utility classes */

.avatar-blue-gem {
  background-color: var(--avatar-blue-gem-surface) !important;
  color: var(--avatar-blue-gem-text-amp-icon) !important;
}

.avatar-oxford-blue {
  background-color: var(--avatar-oxford-blue-surface) !important;
  color: var(--avatar-oxford-blue-text-amp-icon) !important;
}

.avatar-willow-grove {
  background-color: var(--avatar-willow-grove-surface) !important;
  color: var(--avatar-willow-grove-text-amp-icon) !important;
}

.avatar-persian-indigo {
  background-color: var(--avatar-persian-indigo-surface) !important;
  color: var(--avatar-persian-indigo-text-amp-icon) !important;
}

.avatar-meteor {
  background-color: var(--avatar-meteor-surface) !important;
  color: var(--avatar-meteor-text-amp-icon) !important;
}

.avatar-korma {
  background-color: var(--avatar-korma-surface) !important;
  color: var(--avatar-korma-text-amp-icon) !important;
}

.avatar-honey-flower {
  background-color: var(--avatar-honey-flower-surface) !important;
  color: var(--avatar-honey-flower-text-amp-icon) !important;
}

.avatar-green-leaf {
  background-color: var(--avatar-green-leaf-surface) !important;
  color: var(--avatar-green-leaf-text-amp-icon) !important;
}

.avatar-martinique {
  background-color: var(--avatar-martinique-surface) !important;
  color: var(--avatar-martinique-text-amp-icon) !important;
}

.avatar-cloud-burst {
  background-color: var(--avatar-cloud-burst-surface) !important;
  color: var(--avatar-cloud-burst-text-amp-icon) !important;
}

.avatar-ultramarine {
  background-color: var(--avatar-ultramarine-surface) !important;
  color: var(--avatar-ultramarine-text-amp-icon) !important;
}

.avatar-whisper {
  background-color: var(--avatar-whisper-surface) !important;
  color: var(--avatar-whisper-text-amp-icon) !important;
}

.avatar-eastern-blue {
  background-color: var(--avatar-eastern-blue-surface) !important;
  color: var(--avatar-eastern-blue-text-amp-icon) !important;
}

.avatar-tuna {
  background-color: var(--avatar-tuna-surface) !important;
  color: var(--avatar-tuna-text-amp-icon) !important;
}

.avatar-bunting {
  background-color: var(--avatar-bunting-surface) !important;
  color: var(--avatar-bunting-text-amp-icon) !important;
}

.avatar-green-pea {
  background-color: var(--avatar-green-pea-surface) !important;
  color: var(--avatar-green-pea-text-amp-icon) !important;
}

.avatar-kingfisher-daisy {
  background-color: var(--avatar-kingfisher-daisy-surface) !important;
  color: var(--avatar-kingfisher-daisy-text-amp-icon) !important;
}

.avatar-shiraz {
  background-color: var(--avatar-shiraz-surface) !important;
  color: var(--avatar-shiraz-text-amp-icon) !important;
}

.avatar-rouge {
  background-color: var(--avatar-rouge-surface) !important;
  color: var(--avatar-rouge-text-amp-icon) !important;
}

/* global syles */

.app {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background: var(--backgrounds-canvas);
}

.outlet {
  width: 100%;
  height: calc(100vh - 16px);
  margin: 8px;
  overflow-y: auto;
  border-radius: 12px;
  border: 1px solid var(--border-default);
  background: var(--backgrounds-on-canvas);
}

.outlet_inner {
  margin: 24px 40px 24px;
  display: flex;
  margin-inline: auto;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background: transparent;
}

/* for modals */
.modal-container {
  display: flex;
  width: 528px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 12px;
  border: 1px solid var(--border-on-bg);
  background: var(--backgrounds-dropdowns);

  /* Shadow/Shadow__XLarge */
  box-shadow: 0px 20px 25px 0px rgba(42, 42, 42, 0.2);
}

.preference_icon * {
  fill: var(--button-primary);
}

.article.truncate-lines {
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

input.input::placeholder,
input::placeholder,
textarea.input::placeholder {
  text-transform: none !important;
}

textarea::placeholder {
  color: var(--text-disabled) !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 24px !important;
  font-family: 'Geist Variable', Arial, Helvetica, sans-serif !important;
}

[class*='heading'] {
  font-family: 'Geist Variable', Arial, Helvetica, sans-serif !important;
}

[class*='body'] {
  font-family: Inter, sans-serif !important;
}

.page-title {
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  padding: 16px;
  border-bottom: 1px solid var(--border-default);
  background: var(--backgrounds-on-canvas);
}

/* Scrollbar.css */

/* width and height for the scrollbar track */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  border-radius: 100px;
}

/* styles for the scrollbar track */
::-webkit-scrollbar-track {
  /* background: var(--backgrounds-on-bg) !important; */
}

/* styles for the scrollbar handle */
::-webkit-scrollbar-thumb {
  background: var(--backgrounds-default) !important;
  /* border-radius: 5px !important; */
}

/* styles for the scrollbar handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555 !important;
}

svg.success path {
  fill: #036b26;
}
