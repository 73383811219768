@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'Graphik';
    src: url('./assets/fonts/Graphik/GraphikRegular.otf');
    font-weight: 400;
  }

  @font-face {
    font-family: 'Graphik';
    src: url('./assets/fonts/Graphik/GraphikMedium.otf');
    font-weight: 500;
  }

  @font-face {
    font-family: 'Graphik';
    src: url('./assets/fonts/Graphik/GraphikBold.otf');
    font-weight: 700;
  }
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }

  .scrollbar-hidden::-webkit-scrollbar {
    display: none;
  }

  .scrollbar-hidden {
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  .getStarted-gradient {
    background: linear-gradient(
      284.27deg,
      rgba(255, 216, 235, 0.5) 17.62%,
      rgba(254, 249, 194, 0.5) 53.98%,
      rgba(230, 223, 236, 0.5) 80.16%
    );
  }

  .getStarted.titleCompleted {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .getStarted.titleCompleted:before {
    content: '';
    position: absolute;
    background-color: var(--text-default);
    width: 100%;
    height: 1px;
  }
}

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  /*:root {*/
  /*  --foreground-rgb: 255, 255, 255;*/
  /*  --background-start-rgb: 0, 0, 0;*/
  /*  --background-end-rgb: 0, 0, 0;*/
  /*}*/
}

body {
  /* @apply text-grey-500 bg-grey-25; */
}

label {
  /* @apply block text-sm text-grey-400; */
}

input,
select,
textarea,
.input {
  /* @apply rounded border !border-grey-75 bg-white; */
  /* @apply !rounded-lg transition text-sm shadow-sm; */
}

.input.focused,
input:focus,
select:focus,
textarea:focus {
  /* @apply !border-primary-500 !ring !ring-[#6E66CB33]; */
}

.input-clear {
  @apply shadow-none border-none focus:!ring-transparent px-0 !outline-none;
}

[type='checkbox']:checked:hover,
[type='checkbox']:checked:focus,
[type='radio']:checked:hover,
[type='radio']:checked:focus {
  /* @apply bg-primary-600 border-primary-600; */
}

[type='checkbox']:checked,
[type='radio']:checked {
  /* @apply bg-primary-500 border-primary-500; */
}

.btn {
  @apply rounded-xl inline-flex items-center justify-center p-2 px-4 space-x-2 transition-all font-medium;
  @apply cursor-pointer disabled:cursor-auto select-none overflow-hidden;
  @apply transition-all duration-300 active:duration-150 border active:!border-b;
  @apply -translate-y-[3px] hover:-translate-y-[4px];
  @apply active:!-translate-y-[1px] disabled:!translate-y-0;
}

.btn-sm {
  @apply !p-[3px] !px-[10px] text-sm;
}

.btn-md {
  @apply space-x-1 !py-1;
}

.btn-neutral {
  /* @apply btn bg-white text-grey-700; */
  /* @apply active:bg-grey-50 active:border-grey-50;
  @apply active:![box-shadow:0_1px_0_0_#0B032D1A,0_2px_0_0_#0B032D08];
  @apply disabled:![box-shadow:0_0px_0_0_#0B032D1A,0_0px_0_0_#0B032D08] disabled:!bg-grey-50 disabled:!border-grey-50 disabled:!text-grey-200; */
  @apply [box-shadow:0_3px_0_0_#e7e6e9,0_6px_0_0_#0B032D08] hover:[box-shadow:0_4px_0_0_#e7e6e9,0_8px_0_0_#0B032D08];
  /* @apply border-grey-75; */
}

.btn-primary {
  /* @apply btn bg-primary-500 text-white;
  @apply active:![box-shadow:0_1px_0_0_#413c77,0_2px_0_0_#0B032D08];
  @apply hover:bg-primary-600 hover:border-primary-600 active:bg-primary-700 active:border-primary-700; */
  @apply disabled:![box-shadow:0_0px_0_0_#6760bf,0_0px_0_0_#0B032D08] disabled:!bg-[#C4C3CD] disabled:!border-[#C4C3CD];
  @apply [box-shadow:0_3px_0_0_#6760bf,0_6px_0_0_#0B032D08] hover:[box-shadow:0_4px_0_0_#4d478d,0_8px_0_0_#0B032D08];
  /* @apply border-primary-500; */
}

.btn-negative {
  @apply btn bg-negative-500 text-white;
  @apply active:![box-shadow:0_1px_0_0_#6f0604,0_2px_0_0_#0B032D08];
  @apply hover:bg-negative-600 hover:border-negative-600 active:bg-negative-700 active:border-negative-700;
  @apply disabled:![box-shadow:0_0px_0_0_#6760bf,0_0px_0_0_#0B032D08] disabled:!bg-[#C4C3CD] disabled:!border-[#C4C3CD];
  @apply [box-shadow:0_3px_0_0_#a31510,0_6px_0_0_#0B032D08] hover:[box-shadow:0_4px_0_0_#941b17,0_8px_0_0_#0B032D08];
  @apply border-negative-500;
}

.btn-ghost {
  @apply inline-flex items-center justify-center p-2 px-4 text-grey-700 font-medium;
  @apply rounded-xl hover:bg-grey-50 active:bg-grey-75 transition duration-300 active:duration-150;
}

.bg-register {
  background: url('./assets/images/BgRegister.png') no-repeat center;
  background-size: cover;
}

.otp-input {
  @apply !w-[97px] text-[34px] shadow font-medium;
}

.pill {
  @apply px-2 py-1 flex items-center space-x-1 border border-grey-100 rounded-full text-sm;
}

.navbar-list {
  @apply py-4;
}

.navbar-list .navbar-list {
  @apply py-0;
}

.navbar-list .navbar-list .navbar-item {
  /*@apply !pl-10;*/
}

.navbar-item {
  @apply space-x-2.5 flex items-center text-grey-700 hover:text-grey-500 transition text-sm !rounded-xl;
  @apply p-4 !py-1.5 font-medium border border-transparent w-full cursor-pointer;
}

.navbar-item.active {
  @apply bg-grey-50 text-grey-400 btn -translate-y-[3px] !justify-start active:!border-grey-50;
  @apply active:!-translate-y-[1px] hover:-translate-y-[4px] active:![box-shadow:0_1px_0_0_#d4d2da,0_2px_0_0_#0B032D08];
  @apply disabled:translate-y-0 disabled:[box-shadow:0_0px_0_0_#d4d2da,0_0px_0_0_#0B032D08];
  @apply transition-all duration-300 active:duration-150 [box-shadow:0_3px_0_0_#d4d2da,0_6px_0_0_#0B032D08] hover:[box-shadow:0_4px_0_0_#d4d2da,0_8px_0_0_#0B032D08];
  @apply border border-[#EAE9ED] active:border-b;
}

.badge {
  @apply text-white rounded-full p-1 px-1.5 min-w-[3ch] leading-none font-medium text-[10px] text-center bg-negative-500;
}

.tag {
  @apply text-xs text-[var(--text-on-bg)] inline-flex items-center space-x-1 rounded-full px-3 py-1 transition;
  background-color: var(--backgrounds-default);
}

.tag-warning {
  @apply tag !bg-warning-50 !text-warning-700;
}

.tag-blue {
  @apply tag !bg-blue-50 !text-blue-900;
}

.tag-success {
  @apply tag !bg-success-50 !text-success-900;
}

.tag-negative {
  @apply tag !bg-negative-50 !text-negative-900;
}

.dot {
  @apply inline-block aspect-square w-2.5 rounded-full;
}

.dot-warning {
  background-color: var(--color-warning-500);
}

.dot-info {
  background-color: var(--color-positive-500);
}

.dot-success {
  background-color: var(--color-success-500);
}

.dot-negative {
  background-color: var(--color-negative-500);
}

.pill {
  @apply text-xs font-medium rounded-full border border-grey-75 px-2 py-1 text-grey-400 inline-flex items-center space-x-1;
}

.card {
  @apply border border-grey-75 !rounded-xl p-6 bg-white;
}

strong {
  @apply font-medium;
}

input {
  /* Resetting all default styles */
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  background: none;
  font: inherit;
  color: inherit;
  text-align: inherit;
  text-decoration: none;
  cursor: inherit;
  appearance: none; /* Remove default appearance (e.g., buttons, checkboxes, etc.) */
}

[type='checkbox'] {
  outline: none;
  /* background-color: transparent !important; */
  color: #8178ef !important;
  border: transparent !important;
}

input,
textarea {
  -webkit-text-size-adjust: 100% !important;
}

#root {
  position: fixed;
  height: 100dvh;
  width: 100vw;
}
